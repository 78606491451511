.quiz-logo {
    img {
        max-width: 150px;
        border-radius: 50%;
    }
}

.left-side-container {
    width: 50%;
    height: 70vh;
    overflow-y: auto;
}

.right-side-container {
    width: 50%;
    height: 70vh;
    overflow-y: auto;
}

.quiz-question-container {
    width: 100%;
    height: 70vh;
    overflow-y: auto;
}

.quiz-attachment-container {
    iframe {
        height: 55vh;
    }
}

.attachment-image > img {
    vertical-align: middle;
    border-style: none;
    max-height: 250px;
    max-width: 100%;
    padding-right: 48px;
}

.quiz-summary {
    .ql-editor {
        min-height: 350px;
    }
}

.quizTimeLimit {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
}
