.theme-font-color {
    color: rgb(16, 110, 190);
}

.parent-container {
    position: absolute;
    width: 100%;
    top: 50px;
    margin: 0;
    bottom: 0;
}

.parent-container .navigation-content {
    margin: 0px 5px 5px 5px;
    border: 1px solid #666;
    border-radius: 2px;
    overflow-y: auto;
    position: relative;
}

.parent-container .main-menu-parent {
    background: -webkit-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    border: 1px solid darkgray;
}
