.timer-main {
    background-color: white;
    border-radius: 50px;
    padding-right: 50px;
    background-color: rgb(242, 242, 242);
}

.timer {
    font-size: 20px;
    color: rgb(251, 166, 54);
    font-weight: bolder;
}
