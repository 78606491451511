table,
th,
td {
    text-align: center;
    padding: 10px;
    border-collapse: unset;
    border-spacing: 0;
    white-space: nowrap;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
        "Helvetica Neue", sans-serif;
}

.leaderBoardContainer {
    padding: 20px 0px;
    background-color: #161616fa;
}

.stackColor {
    color: white;
    padding: 0 20px;
}

.badgePos {
    padding-left: 30px;
}

table {
    color: white;
    margin-top: 20px;
    width: 100%;
    padding: 0px;
}

.loader {
    display: grid;
    place-items: center;
    height: 100vh;
}

.pos1 {
    background-color: #03045e;
    font-size: 36px;
}

.pos2 {
    background-color: #023e8a;
    font-size: 32px;
}

.pos3 {
    background-color: #0077b6;
    font-size: 28px;
}
.pos4 {
    background-color: #0096c7;
    font-size: 24px;
}
.pos5 {
    background-color: #00b4d8;
    font-size: 24px;
}
.pos6 {
    background-color: #48cae4;
    font-size: 22px;
    color: black;
}
.pos7 {
    background-color: #90e0ef;
    font-size: 18px;
    color: black;
}
.pos8 {
    background-color: #ade8f4;
    font-size: 18px;
    color: black;
}
.pos9 {
    background-color: #caf0f8;
    font-size: 18px;
    color: black;
}

.modalWidth {
    max-width: 350px;
}

.settings {
    cursor: pointer;
}

.eventLogo {
    border-radius: 16px;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
