.rating-option {
    border: 1px black solid;
    border-radius: 50%;
    text-align: center;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.rating-option:hover {
    background-color: #1c51ad;
    filter: brightness(150%);
}
