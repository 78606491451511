.quizSelectionView-background {
    height: 100vh;
    background: -webkit-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    justify-content: center;
}

.quizSelectionView-component-container {
    padding: 50px;
    background-color: white;
    border-radius: 5px;
    width: 500px;

    .logo {
        background-image: url(../Images/logo.svg);
        background-repeat: no-repeat;
        width: 100%;
        height: 120px;
        background-size: 100% 100%;
        margin: 0 auto 20px auto;
    }
}
