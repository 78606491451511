$fontSizeChangeBreakPoint: 1300px;

.dropContainerText {
    height: 100px;
    border: 3px black solid;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px;
    color: #ffff;
}

.dropContainerText:hover {
    filter: brightness(300%);
}

@media only screen and (max-width: $fontSizeChangeBreakPoint) {
    .dropContainerText {
        font-size: 12px;
    }
}

.fileText {
    cursor: pointer;
    font-size: 16px;
    color: #ffff;
}

.fileContainer {
    display: flex;
    align-items: center;
}
