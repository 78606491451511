@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$errorMessageColor: rgb(171,
55,
61);
$successMessageColor: #226522;
$defaultButtonBackgroundColor: #e5e5e5;
$defaultButtonBorderColor: #8a8886;
$selectedOptionColor: #54c654;
$quillBackgroundColor: #4d7cb9;
$completed-connector-button-color: #8a8886;
$blueGridFontColor: white;
$quillContentGridFontColor: black;
$tags-background-color: #f0f0f0;
$tags-border-color: #7272a7;
$infoOpsColor: #c69654;
$infoFlightCrew: #6654c6;
$eventCodesBackgroundColor: #e6ffe6;
$eventCodesBorderColor: #b3ffb3;
$eventCodesFontColor: #000;
.info-ops-background-color {
    background-color: $infoOpsColor !important;
}

.info-flight-background-color {
    background-color: $infoFlightCrew !important;
}

.text-align-center {
    text-align: center;
}

.margin-top-100 {
    margin-top: 100px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-10 {
    margin-top: 10px;
}

.padding-10 {
    padding: 10px;
}

.padding-left-0 {
    padding-left: 0px !important;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-25 {
    padding: 25px !important;
}

.margin-left-40 {
    margin-left: 40px;
}

.margin-left-10 {
    margin-left: 10px;
}

.font-size-25 {
    font-size: 25px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-50 {
    font-size: 50px;
}

.font-size-40 {
    font-size: 40px;
}

.width-100 {
    width: 100px;
}

.width-90per {
    width: 90%;
}

.width-80per {
    width: 80%;
}

.justify-content-center {
    justify-content: center;
}

.display-flex {
    display: flex;
}

.justify-content-flex-end {
    justify-content: flex-end;
}

.padding-10 {
    padding: 10px;
}

.padding-30 {
    padding: 30px !important;
}

.margin-10 {
    margin: 10px;
}

.margin-30 {
    margin: 30px;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-top-0 {
    margin-top: 0px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-bottom-10-negative {
    margin-bottom: -10px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-8 {
    margin-top: 8px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.margin-top-35 {
    margin-top: 35px !important;
}

.margin-top-80 {
    margin-top: 80px !important;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-left-right-10 {
    margin: 0px 10px 0px 10px !important;
}

.margin-left-20 {
    margin-left: 20px !important;
}

.margin-left-40 {
    margin-left: 40px !important;
}

.margin-left-48 {
    margin-left: 48px !important;
}

.margin-left-55 {
    margin-left: 55px !important;
}

.margin-left-75 {
    margin-left: 75px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-right-20 {
    margin-right: 20px !important;
}

.margin-right-48 {
    margin-right: 48px !important;
}

.margin-5 {
    margin: 5px;
}

.margin-10 {
    margin: 10px;
}

.width-100 {
    width: 100px;
}

.width-120 {
    width: 120px;
}

.width-125 {
    width: 125px !important;
}

.width-150 {
    width: 150px;
}

.width-200 {
    width: 200px;
}

.width-300 {
    width: 300px;
}

.width-20per {
    width: 20%;
}

.width-100per {
    width: 100% !important;
}

.width-30per {
    width: 30%;
}

.height-50 {
    height: 50px;
}

.height-55 {
    height: 55px;
}

.height-100 {
    height: 100px;
}

.height-95 {
    height: 95px;
}

.height-80 {
    height: 80px;
}

.height-120 {
    height: 120px;
}

.height-125 {
    height: 125px;
}

.height-150 {
    height: 150px;
}

.height-155 {
    height: 155px;
}

.height-100per {
    height: 100%;
}

.height-75vh {
    height: 75vh !important;
}

.top-50 {
    top: 50px;
}

.top-0 {
    top: 0;
}

.align-self-center {
    align-self: center;
}

.search-section {
    background: #f0f0f0;
}

.visibility-hidden {
    visibility: hidden;
}

.background-color-aquamarine {
    background: rgb(255, 255, 255);
    background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
}

.section-background {
    background-color: #f8f8f8;
    padding: 10px;
}

.section-border {
    border: 1px solid darkgray;
    border-radius: 2px;
}

.border-yellow {
    border: 1px rgba(241, 196, 15, 0.8) solid;
    background-color: rgba(241, 196, 15, 0.3);
}

.success-background {
    background-color: rgba(7, 188, 12, 0.3);
    border: 1px solid rgba(7, 188, 12, 0.6);
}

.danger-background {
    background-color: rgba(231, 76, 60, 0.3);
    border: 1px solid rgba(231, 76, 60, 0.6);
}

.tag-container {
    border: 1px solid;
    padding: 2px 5px;
    border-radius: 2px;
    border-color: rgb(114, 114, 167);
    margin-left: 3px;
}

.loading-component {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.minHeight-100vh {
    min-height: 100vh;
}

.minHeight-70vh {
    min-height: 70vh;
}

.quill-minHeight-32px {
    .ql-editor {
        min-height: 32px !important;
    }
}

.height-70vh {
    height: 70vh;
}

.display-none {
    display: none !important;
}

.display-block {
    display: block !important;
}

.answer-result {
    color: black;
}

.border-radius-5 {
    border-radius: 5px;
}

.border-radius-10 {
    border-radius: 10px;
}

.error-message {
    color: $errorMessageColor;
    font-size: 12px;
}

.points-textfield-width {
    width: 70px;
}

.info-color {
    color: white;
}

.cursor {
    cursor: pointer;
}

.hover-cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

.margin-0 {
    margin: 0px !important;
}

.padding-0 {
    padding: 0px !important;
}

.overflowy-auto {
    overflow-y: auto;
}

.overflowy-hidden {
    overflow-y: hidden;
}

.max-height-75vh {
    max-height: 75vh;
}

.max-height-60vh {
    max-height: 60vh;
}

.position-sticky {
    position: sticky;
}

.header-image-background-color {
    background-color: white;
}

.height-85 {
    height: 85px;
}

.height-60vh {
    height: 60vh;
}

.height-25per {
    height: 25%;
}

.height-60per {
    height: 60%;
}

.image-width-100per {
    img {
        max-width: 100%;
    }
}

.pointer-event-none {
    pointer-events: none !important;
}

.font-weight-bold {
    font-weight: bold;
}

.link-color-white {
    a {
        color: white;
    }
}

.error-message-font-color {
    color: $errorMessageColor;
}

.max-width-100per {
    max-width: 100%;
}

.position-absolute {
    position: absolute;
}

.zIndex-9999 {
    z-index: 99999;
}

.opacity-1 {
    opacity: 1 !important;
}

.success-message {
    color: $successMessageColor;
    padding: 2px 0px 0px;
}

.max-height-100per {
    max-height: 100%;
}

.opacity-point3 {
    opacity: 0.3 !important;
}

.loading-spinner {
    position: absolute;
    left: 50%;
    top: 45%;
}

.dashed-border-default-button {
    background-color: $defaultButtonBackgroundColor;
    border: 1px dashed $defaultButtonBorderColor;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}

.height-auto {
    height: auto;
}

.width-168 {
    width: 168px;
}

.min-width-168 {
    min-width: 168px;
}

.width-35per {
    width: 35%;
}

.selected-connector-button {
    background-color: $completed-connector-button-color !important;
}

.margin-p-0 {
    p {
        margin: 0 !important;
    }
}

.padding-left-10 {
    padding-left: 10px !important;
}

.padding-right-10 {
    padding-right: 10px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-0 {
    padding-top: 0 !important;
}

.max-width-300 {
    max-width: 300px;
}

.quill-blue-background {
    .ql-editor {
        background-color: $quillBackgroundColor;
    }
}

.height-100vh {
    height: 100vh !important;
}

.grid-blue-background {
    background-color: $quillBackgroundColor;
    color: $quillContentGridFontColor !important;
}

.grid-blue-background :hover {
    background-color: $quillBackgroundColor;
}

.grid-font-color {
    color: $blueGridFontColor !important;
}

.grid-font-color:hover {
    color: $blueGridFontColor !important;
}

.quiz-question-tag-container {
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
    margin-right: 4px;
    background-color: $tags-background-color;
    font-size: 10px;
    border-color: $tags-border-color;
}

.margin-right-10 {
    margin-right: 10px;
}

.max-width-46per {
    max-width: 46%;
}

.max-width-400 {
    max-width: 400px;
}

.width-250px {
    width: 250px !important;
}

.position-relative {
    position: relative;
}

.align-item-to-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.event-codes {
    background-color: $eventCodesBackgroundColor;
    border-radius: 2px;
    border: 2px dashed $eventCodesBorderColor;
    padding: 5px;
    width: 130px;
    display: inline-block;
    text-align: center;
    color: $eventCodesFontColor;
}

::-webkit-scrollbar {
    width: 10px;
    
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: #efefef;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #efefef; 
    border-radius: 10px;
  }

  .BillingAddressForm {
    input {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        font-size: 16px;
        font-weight: normal;
        line-height: 23px;
        padding: 13px 20px;
        color: black;
        margin-bottom: 10px;
    }
}

.PlacesAutocomplete {
    position: relative;
    ul {
        position: absolute;
        background: white;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        padding: 5px 0px;
        z-index: 9999;
        li {
            list-style-type: none;
            padding: 10px 20px;
            &:hover {
                background: whitesmoke;
                cursor: pointer;
            }
        }
        
    }
}

.manual-entry {
    color: '#1F71B6';
    font-weight: 600;
    font-size: 12px;
    padding: 5px 20px 10px 0px;
    display: block;
    cursor: pointer;
}

.BillingAddressForm__manualInputs {
    select {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        font-size: 16px;
        // font-weight: normal;
        // line-height: 23px;
        padding: 14px 13px;
        color: black;
        margin-bottom: 10px;
        width: calc(100%);
        
    }
    input {
        width: calc(50% - 5px);
        &:first-of-type {
            width: 100%;
        }
        &:nth-child(3),
        &:nth-child(5) {
            margin-right: 5px;
        }
        &:nth-child(4),
        &:nth-child(6) {
            margin-left: 5px;
        }
    }
}

.MuiAutocomplete-popup {
    z-index: 999999 !important;
}

.modal {
    z-index: 9999 !important;
}

.StripeForm {
    .StripeForm__bookNow {
        margin-bottom: 10px;
        min-height: 50px;
    }
}

.StripeCardElement {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 13px 20px;
    border-radius: 8px;
    margin-bottom: 10px;
    background: #ffff;
}

.AddCard {
    margin-top: 10px;
    > input {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        font-size: 16px;
        font-weight: normal;
        line-height: 23px;
        padding: 13px 20px;
        color: black;
        margin-bottom: 10px;
    }
    span {
        color: red;
    }
    #cardNumber {
        width: 100%;
    }
    #expiryDate {
        width: calc(50% - 5px);
        margin-right: 5px;
    }
    #cvc {
        width: calc(50% - 5px);
        margin-left: 5px;
    }
}