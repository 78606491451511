.description-text {
    .ql-editor {
        min-height: 35vh;
    }
}

.referenceItems-list {
    height: 70vh;
    overflow: auto;
}

.reference-cover-image > img {
    vertical-align: middle;
    border-style: none;
    max-height: 250px;
    max-width: 100%;
    padding-right: 48px;
}
