.same-line-label-textfield-container {
    display: flex;
    margin-left: 10px;
}

.same-line-label-textfield-container .label {
    float: left;
}
.same-line-label-textfield-container .textfield {
    flex-grow: 1;
    flex-shrink: 1;
}

.center-content {
    display: flex;
    align-items: center;
}

.labelText {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
}
