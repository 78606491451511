$eventClosedMessageColor: red;
.event-details-panel {
    .ms-Panel-content {
        height: 100%;
    }
    .ms-Panel-headerText {
        font-size: 24px;
    }
    .ms-DetailsRow-cell {
        font-size: 16px;
    }
    .ms-Label {
        font-size: 18px;
    }
}
.event-codes {
    background-color: #e6ffe6;
    border-radius: 2px;
    border: 2px dashed #b3ffb3;
    padding: 5px;
    width: 130px;
    display: inline-block;
    text-align: center;
    color: black;
}
.event-codes-list-container {
    width: 100%;
    height: 80vh;
}
.event-closed-message {
    display: flex;
    justify-content: center;
    color: $eventClosedMessageColor;
}

.black-border {
    border-color: black;
    border-right-color: black !important;
}

.file-card {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
}

.small-event-codes {
    background-color: #e6ffe6;
    border-radius: 2px;
    border: 2px dashed #b3ffb3;
    padding: 5px;
    width: 80px;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    color: black;
}

.cardSubText {
    font-weight: bold;
    font-size: 14px;
    display: inline;
    margin-right: 3px;
}

.cardLabel {
    font-size: 12px;
    word-break: break-all;
    p {
        margin-bottom: 0px;
    }
}

.referenceCode {
    border: 1px solid rgb(96, 94, 92);
    background: white;
    width: 100%;
    padding: 6px;
    height: 32px;
}

.flex-gap {
    display: flex;
    align-items: center;
    gap: 35px;
}

.flex-row {
    display: flex;
    align-items: center;
}

.grid-column {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    align-items: center;
    column-gap: 35px;
}
