$textFieldBorderColor: rgb(96, 94, 92);
$fontSizeChangeBreakPoint: 1200px;
.advance-text-editor {
    .ql-editor {
        min-height: 110px;
    }
    .ql-tooltip {
        left: 20% !important;
        top: 30px !important;
        input[type="text"] {
            width: 200px;
        }
    }
}

.ql-toolbar {
    background-color: white;
}

.ql-editor {
    background-color: white;
    p {
        font-size: 17px;
    }
}

.basic-text-editor {
    .ql-editor {
        min-height: 80px;
    }
}

.simple-text-editor {
    .ql-toolbar {
        display: none;
    }
    .ql-container {
        border-top: 1px solid !important;
        border: 1px solid $textFieldBorderColor;
    }
    .ql-editor {
        min-height: 32px;
        padding: 7px 5px 5px 5px !important;
    }
}

@media only screen and (max-width: 900px) {
    .advance-text-editor {
        .ql-tooltip {
            left: 200px !important;
        }
    }
}

@media only screen and (max-width: 780px) {
    .advance-text-editor {
        .ql-tooltip {
            left: 10px !important;
            input[type="text"] {
                width: auto;
            }
        }
    }
}
