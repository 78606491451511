@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.is-dropping {
    border-top: 3px #0078d4 solid !important;
}

.boxShadow {
    box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.0168519), 0px 14.2593px 46.8519px rgba(0, 0, 0, 0.0274815), 0px 8.47407px 25.4815px rgba(0, 0, 0, 0.035), 0px 4.4px 13px rgba(0, 0, 0, 0.0425185), 0px 1.79259px 6.51852px rgba(0, 0, 0, 0.0531481), 0px 0.407407px 3.14815px rgba(0, 0, 0, 0.07);
}