.field-title {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: #ffff;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0px;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;
    margin-top: 10px;
}

.required {
    color: red;
    font-size: 16px;
}

.choiceGroup {
    .ms-ChoiceFieldGroup {
        .ms-ChoiceFieldGroup-flexContainer {
            .ms-ChoiceField {
                color: #ffff;
            }
        }
    }
}
