.quill-editor {
    margin-bottom: 20px;
    div {
        width: 100%;
        height: fit-content;
    }
}

.question-image > img {
    vertical-align: middle;
    border-style: none;
    max-height: 250px;
    max-width: 100%;
    padding-right: 48px;
}

.tags-modal {
    .ms-Dialog-main {
        height: 80%;
        width: 50%;
        .ms-Modal-scrollableContent {
            height: 100% !important;
            overflow: hidden;
        }
    }
}

.hint-text {
    .ql-editor {
        min-height: 30vh;
    }
}

.hints-modal {
    .ms-Dialog-main {
        height: 85%;
        width: 50%;
        .ms-Modal-scrollableContent {
            height: 100% !important;
            overflow: auto;
        }
    }
}

.inline-label {
    display: inline;
}

.flex-center {
    display: flex;
    align-items: center;
}
