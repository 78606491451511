$border: 3px;
$borderColor: #000000;
$borderRadius: 10px;
$backgroundColor: #4d7cb9;
$opacity: 0.9;
$fontColor: white;
$container_border: 3px;
$close_icon_color: #1c51ad;
$hover_button_background_color: #1c51ad;
$correct_answer_background_color: #54c654;
$wrong_answer_background_color: #d62d2d;
$medium_break_point: 992px;
$logo_background_color: #ffff;
$fontSizeChangeBreakPoint: 1300px;
$compactViewBreakDownPoint: 1300px;
$fontSizeAfterBreakPoint: 12px;
$small_break_point: 576px;
.main-container {
    background-image: url("../../../Images/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    opacity: 5;
    overflow-y: auto;
    .left-section-logo {
        padding: 0 12.5px 12.5px 25px;
        height: 10%;
    }
    .left-section-info {
        padding: 0 12.5px 12.5px 25px;
        height: auto;
        flex-grow: 1;
    }
    .left-section-document {
        padding: 12.5px 12.5px 25px 25px;
        height: auto;
        flex-grow: 100;
        overflow-y: auto;
    }
    .left-section-reference-info {
        padding: 0 12.5px 12.5px 25px;
        height: 45%;
    }
    .show-on-small-screen {
        display: none;
        @media only screen and (max-width: $compactViewBreakDownPoint) {
            display: block;
        }
    }
    .hide-on-small-screen {
        display: block;
        @media only screen and (max-width: $compactViewBreakDownPoint) {
            display: none;
        }
    }
    .connector-question-text {
        font: normal normal normal 20px/24px;
    }
    .left-section-reference-document {
        padding: 12.5px 12.5px 20px 25px;
        height: 45%;
    }
    .middle-section-question {
        padding: 0px 25px 12.5px 12.5px;
        height: 65%;
    }
    .feedback-section {
        padding: 0px 12.5px 12.5px 12.5px;
        max-height: calc(100% - 25px);
        overflow-y: auto;
    }
    .middle-section-answer {
        padding: 12.5px 25px 25px 12.5px;
        height: 35%;
    }
    .right-section-cover-image {
        padding: 0px 25px 25px 12.5px;
        height: 70%;
    }
    .right-section-cover-image-height-100 {
        padding: 0px 25px 25px 12.5px;
        height: 100%;
    }
    .right-section-hint {
        padding: 0px 25px 25px 12.5px;
        height: 30%;
    }
    .right-section-refrence {
        height: calc(100% - 20px);
    }
    .middle-section-reference {
        padding: 0px 12.5px 20px 12.5px;
        height: 100%;
        opacity: 0.9;
    }
    .document-view {
        padding: 0px 20px 40px 20px;
        height: 100%;
    }
    .socket-game-history {
        padding-left: 0px;
        height: 100%;
    }
    .socket-hint {
        padding-left: 0px;
        height: 100%;
    }
    .document-cover-image-view {
        padding: 0px;
        padding-bottom: 5px;
        justify-content: center;
        max-height: calc(100% - 40px);
        min-height: fit-content;
        margin: 20px;
        max-width: calc(100% - 40px);
        display: flex;
        .document-cover-image-section {
            border-radius: $borderRadius;
            width: fit-content;
            overflow-y: auto;
            max-height: 83%;
            min-height: fit-content;
            opacity: 1;
            margin: 0px 75px 0px 75px;
        }
    }
}

.question-view {
    background-color: $backgroundColor;
    padding: 20px;
    border: $container_border solid $borderColor;
    border-radius: $borderRadius;
    height: 100%;
    overflow-y: auto;
    opacity: $opacity;
    padding-right: 10px;
}

.feedback-view {
    background-color: $backgroundColor;
    padding: 20px;
    border: $container_border solid $borderColor;
    border-radius: $borderRadius;
    height: fit-content;
    overflow-y: auto;
    opacity: $opacity;
    padding-right: 10px;
}

.middle-section-connector-question {
    padding: 0px 12.5px 12.5px 12.5px;
    max-height: calc(100vh - 50px);
    height: fit-content;
    overflow-y: auto;
}

.quiz-info {
    background-color: $backgroundColor;
    padding: 20px;
    border: $container_border solid $borderColor;
    border-radius: $borderRadius;
    width: 100%;
    height: 100%;
    opacity: $opacity;
    overflow-y: auto;
    .timing {
        background: transparent linear-gradient(180deg, #db1f1f 0%, #720000 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: $border solid $borderColor;
        border-radius: $borderRadius;
        opacity: 1;
        text-align: center;
        line-height: 23px;
        color: $fontColor;
        height: 100%;
        width: 100%;
        span {
            font-size: 15px;
        }
    }
    .quiz-title {
        border: $border $borderColor solid;
        border-radius: $borderRadius;
        padding: 5px;
        background-color: #5488c6;
        font-size: 15px;
        color: $fontColor;
    }
    .quiz-score-info {
        border: $border $borderColor solid;
        border-radius: $borderRadius;
        padding: 5px;
        width: 100%;
        height: 100%;
        background-color: #5488c6;
        line-height: 23px;
        color: $fontColor;
        text-align: center;
        span {
            font-size: 15px;
        }
    }
}

@media only screen and (max-width: $fontSizeChangeBreakPoint) {
    .main-container {
        .left-section-document {
            padding-top: 0px !important;
        }
        .middle-section-answer {
            padding-top: 0px !important;
            padding-right: 25px !important;
        }
        .middle-section-question {
            padding-right: 25px !important;
        }
        .quiz-answer-history-view {
            margin-left: 0px !important;
        }
        .quiz-answer-view {
            margin-left: 0px !important;
            .answer-input {
                font-size: 12px !important;
            }
        }
    }
    .left-section-reference-info {
        padding-left: 12.5px !important;
    }
    .left-section-reference-document {
        padding-left: 12.5px !important;
    }
    .quiz-info {
        .quiz-items-container {
            margin-top: 8px;
        }
        .quiz-score-info {
            span {
                font-size: 12px !important;
            }
        }
    }
    .quiz-title {
        font-size: 12px !important;
    }
    .timing {
        span {
            font-size: 12px !important;
        }
    }
    .zoom-image {
        left: 120px !important;
    }
}

.quiz-document-section {
    background-color: $backgroundColor;
    padding: 20px;
    border: $container_border solid $borderColor;
    border-radius: $borderRadius;
    width: 100%;
    overflow-y: auto;
    opacity: $opacity;
    height: 100%;
}

.document-section {
    background-color: white;
    border: $border solid $borderColor;
    border-radius: $borderRadius;
    width: 80%;
    overflow-y: auto;
    height: auto;
    max-height: 100%;
    padding: 0px 20px 40px 20px;
    opacity: 1;
    margin: 0 auto 0 auto;
}

.attachment-title {
    font-size: 17px;
    color: #ffff;
}

.answer-title {
    font-size: 17px;
}

.quiz-cover-image {
    background-color: $backgroundColor;
    padding: 20px;
    border: $container_border solid $borderColor;
    border-radius: $borderRadius;
    height: 100%;
    opacity: $opacity;
    margin-bottom: 50px;
    .reference-close-button {
        padding: 10;
        height: 95px;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        box-shadow: 0px 3px 6px #00000029;
        border: $border solid $borderColor;
        border-radius: $borderRadius;
        background: transparent linear-gradient(180deg, #6a54c6 0%, #c32290 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        span {
            font-size: 19px;
        }
    }
    .quiz-close-button {
        padding: 10;
        height: 95px;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        box-shadow: 0px 3px 6px #00000029;
        border: $border solid $borderColor;
        border-radius: $borderRadius;
        background-color: $backgroundColor;
        pointer-events: none;
        opacity: 1;
        span {
            font-size: 19px;
        }
        .correct-percentage {
            font-size: 20px;
            color: $fontColor;
        }
    }
}

.quiz-cover-image::-webkit-scrollbar {
    display: none;
}

.middle-section-connector-question::-webkit-scrollbar {
    display: none;
}

.quiz-answer-history-view {
    background-color: #5488c6;
    padding: 20px;
    border: $container_border solid $borderColor;
    border-radius: $borderRadius;
    height: 100%;
    overflow-y: auto;
    opacity: $opacity;
    margin-left: 12.5px;
}

.quiz-answer-view {
    background-color: #5488c6;
    padding: 20px;
    border: $container_border solid $borderColor;
    border-radius: $borderRadius;
    height: 100%;
    overflow-y: auto;
    opacity: $opacity;
    .answer-input {
        border: $border $borderColor solid;
        border-radius: $borderRadius;
    }
    .question-hints {
        background-color: inherit;
        padding: 20px;
        border: $border solid $borderColor;
        border-radius: $borderRadius;
        width: 100%;
        text-align: center;
        button {
            min-height: 60px;
            min-width: 120%;
            background-color: $backgroundColor;
            border: $border $borderColor solid;
            border-radius: $borderRadius;
            background: transparent linear-gradient(180deg, #6a54c6 0%, #c32290 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: $borderRadius;
            opacity: 1;
            span {
                font-size: 20px;
                color: $fontColor;
            }
        }
        .question-hints-info {
            font-size: 15px;
            text-align: justify;
            padding-left: 30px;
        }
    }
}

.answer-submit {
    padding-left: 30px;
    padding-right: 30px;
    background: transparent linear-gradient(180deg, #6a54c6 0%, #c32290 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: $border solid $borderColor;
    border-radius: $borderRadius;
    opacity: 1;
    line-break: normal;
    height: 100%;
}

.answer-submit:disabled {
    border: $border $borderColor solid;
}

.jambar-logo-view-parent {
    height: 100%;
    padding-right: 5.5px;
    padding-left: 17px;
}

.event-logo-view-parent {
    height: 100%;
    padding-left: 5.5px;
    padding-right: 17px;
}

.correct-answer-text {
    font-size: 20px;
    width: 100%;
    text-align: center;
}

.refrence-view {
    background-color: $backgroundColor;
    padding: 20px;
    border: $border solid $borderColor;
    border-radius: $borderRadius;
    height: 100%;
    overflow-y: auto;
    .refrence-options {
        text-align: center;
        border: $border $borderColor solid;
        color: $fontColor;
        border-radius: $borderRadius;
        padding: 10px;
        margin-bottom: 20px;
        word-wrap: break-word;
        min-height: 95px;
        color: $fontColor;
        background-color: #4a7fd4;
        width: 100%;
    }
    .refrence-options:hover {
        background-color: auto !important;
        filter: brightness(150%);
    }
    .refrence-info {
        font-size: 15px;
    }
    .refrence-options-connector {
        text-align: center;
        border: $border $borderColor solid;
        color: $fontColor;
        border-radius: $borderRadius;
        padding: 10px;
        margin-bottom: 20px;
        word-wrap: break-word;
        font: normal normal normal 20px/24px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 95px;
        color: white;
        background-color: #4a7fd4;
    }
    span {
        width: 100%;
        p {
            margin: 0px !important;
        }
    }
    .refrence-options-connector:hover {
        background-color: auto !important;
        filter: brightness(150%);
    }
}

.submit-button-option {
    width: 100%;
    text-align: center;
    border: $border $borderColor solid;
    color: $fontColor;
    border-radius: $borderRadius;
    padding: 10px;
    margin-bottom: 20px;
    word-wrap: break-word;
    font-size: 16px;
    background: transparent linear-gradient(180deg, #6a54c6 0%, #c32290 100%) 0% 0% no-repeat padding-box;
}

.hintButtonText {
    font-size: 17px;
}

.correct-answer-view {
    background-color: $correct_answer_background_color;
}

.option-select {
    background-color: $correct_answer_background_color !important;
}

.wrong-answer-view {
    background-color: $wrong_answer_background_color;
}

.answer-result-view::-webkit-scrollbar {
    width: 0;
}

.refrence-options {
    text-align: center;
    border: $border $borderColor solid;
    color: $fontColor;
    border-radius: $borderRadius;
    padding: 10px;
    margin-bottom: 20px;
    word-wrap: break-word;
    width: 100%;
    background-color: #4a7fd4;
    font-size: 15px;
}

.left-section-column {
    display: flex;
    flex-direction: column;
}

.refrence-options:hover {
    background-color: auto !important;
    filter: brightness(150%);
    border: $border $borderColor solid;
}

.document-view {
    .modal-dialog {
        height: 90%;
        .modal-content {
            height: 90%;
            .modal-body {
                height: 100%;
            }
        }
    }
}

.quiz-container {
    height: calc(100vh - 25px);
    margin-top: 25px !important;
}

.reference-modal-description {
    .modal-dialog {
        height: 90%;
        .modal-content {
            height: 90%;
            background-color: $backgroundColor;
            .modal-header {
                border: 0;
            }
            .modal-title {
                color: $fontColor;
            }
            .modal-body {
                height: 100%;
                color: $fontColor;
            }
            .modal-footer {
                border: 0px;
            }
        }
    }
}

.hints-section {
    p {
        font-size: 15px;
    }
}

@media only screen and (max-width: $medium_break_point) and (min-width: $small_break_point) {
    .event-logo-view-parent {
        padding-right: 0px !important;
    }
    .main-container {
        .left-section-logo {
            padding-right: 2.5px !important;
        }
    }
}

@media only screen and (max-width: $small_break_point) {
    .main-container {
        .right-section-cover-image-height-100 {
            padding-right: 12.5px !important;
        }
        .refrence-quiz-cover-image {
            margin-right: 12.5px !important;
        }
        .left-section-logo {
            padding-left: 12.5px !important;
        }
        .right-section-cover-image {
            padding-right: 12.5px;
        }
        .left-section-document {
            padding-left: 12.5px;
        }
        .left-section-info {
            padding-left: 12.5px;
        }
        .right-section-hint {
            padding-right: 12.5px;
        }
        .socket-game-history {
            padding-left: 12.5px;
            margin-top: 12.5px;
        }
        .socket-hint {
            padding-left: 12.5px;
            margin-top: 12.5px;
        }
        .middle-section-question {
            padding-right: 12.5px !important;
        }
        .middle-section-answer {
            padding-right: 12.5px !important;
        }
    }
    .middle-section-ref-container {
        height: auto !important;
    }
    .middle-section-cover-container {
        height: auto !important;
    }
}

.points-text {
    font-size: 50px;
}

.answerSection {
    font-size: 15px;
}

@media only screen and (max-width: $fontSizeChangeBreakPoint) {
    .hints-section {
        p {
            font-size: $fontSizeAfterBreakPoint;
        }
    }
    .answerSection {
        font-size: $fontSizeAfterBreakPoint;
    }
    .correct-answer-text {
        font-size: $fontSizeAfterBreakPoint;
    }
    .points-text {
        font-size: 25px;
    }
    .event-logo-view-parent {
        padding-right: 5px;
    }
    .refrence-options-connector {
        font-size: $fontSizeAfterBreakPoint;
        min-height: 60px !important;
    }
    .refrence-options {
        font-size: $fontSizeAfterBreakPoint;
    }
    .submit-button-option {
        font-size: $fontSizeAfterBreakPoint;
    }
    .quiz-close-button {
        height: 65px !important;
        span {
            font-size: 12px !important;
        }
    }
    .quiz-cover-image {
        img {
            height: calc(100% - 65px) !important;
        }
    }
    .answer-submit {
        font-size: $fontSizeAfterBreakPoint;
    }
}

.document-modal-parent {
    opacity: 1;
    height: 100%;
    max-width: 100%;
}

.close-icon {
    background-color: white;
    border: 0;
    border-radius: 100%;
    position: absolute;
    color: $close_icon_color;
    margin-right: 20px;
    margin-top: 10px;
    i {
        font-size: 20px !important;
    }
}

.question-Timing {
    color: white;
    font-weight: bold;
    font-size: 20px;
    height: 20px !important;
}

.document-section-items {
    margin-top: 20px;
    text-align: center;
    span {
        font-size: 15px;
        color: white;
        text-align: center;
        margin-top: 6px !important;
    }
    img {
        margin: auto;
        display: block;
        height: 85px;
    }
}

.document-section-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 5%;
}

.quizQuestionview {
    iframe {
        display: flex;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        min-height: 200px;
    }
    p {
        margin: 0 !important;
        font-size: 15px;
    }
}

.question-cover-image {
    max-width: 400px;
}

@media only screen and (max-width: $fontSizeChangeBreakPoint) {
    .quizQuestionview {
        p {
            font-size: 12px;
        }
        h2 {
            font-size: 1rem;
        }
    }
    .answer-title {
        font-size: 14px;
    }
    .attachment-title {
        font-size: 14px;
        text-align: center;
        margin-left: 0px;
        margin-bottom: 5px;
    }
    .question-cover-image {
        max-width: 300px;
    }
    .document-section-items {
        text-align: center;
        margin-top: 0px;
        span {
            font-size: 12px;
            margin-top: 0px !important;
        }
        img {
            height: 50px;
        }
    }
    .document-section-container {
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
        grid-gap: 5%;
    }
}

.scroll-icon {
    position: absolute;
    color: white;
    font-size: 30px;
    background-color: inherit;
    width: calc(100% - 55px);
    margin-left: 23px;
    left: 0;
}

.scroll-icon-document-modal {
    position: absolute;
    font-size: 30px;
    color: $fontColor;
}

.quill-editor-text-align {
    .ql-align-center {
        text-align: center !important;
    }
    .ql-align-right {
        text-align: right !important;
    }
    .ql-align-justify {
        text-align: justify !important;
    }
}

.logo-view {
    height: 100%;
    justify-content: center;
    display: flex;
    background-color: $logo_background_color;
    border-radius: 5px;
}

.game-logos {
    max-height: 100%;
    max-width: 100%;
    border-radius: 5px;
}

.document-modal-pdf {
    height: calc(100vh - 57px);
    width: 100%;
}

.refrence-quiz-cover-image {
    background-color: $backgroundColor;
    padding: 20px;
    margin: 0 25px 0 12.5px;
    border: $container_border solid $borderColor;
    border-radius: $borderRadius;
    height: 100%;
    opacity: $opacity;
    .image-height-full {
        height: 100%;
        width: 100%;
    }
    .image-height-fit {
        height: calc(100% - 95px);
        width: 100%;
        object-fit: contain;
    }
    .reference-close-button {
        padding: 10;
        height: 95px;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        box-shadow: 0px 3px 6px #00000029;
        border: $border solid $borderColor;
        border-radius: $borderRadius;
        background: transparent linear-gradient(180deg, #6a54c6 0%, #c32290 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        span {
            font-size: 19px;
        }
    }
}

@media only screen and (max-width: $compactViewBreakDownPoint) and (min-width: $small_break_point) {
    .main-container {
        .left-section-reference-info {
            padding-left: 12.5px;
            padding-right: 0px;
        }
        .left-section-reference-document {
            padding-left: 12.5px;
            padding-right: 0px;
        }
        .middle-section-reference {
            padding-left: 12.5px;
            padding-right: 0px;
        }
        .left-section-document {
            padding-left: 25px;
            padding-right: 0px;
        }
        .left-section-info {
            padding-left: 25px;
            padding-right: 0px;
        }
        .middle-section-connector-question {
            padding-left: 12.5px;
            padding-right: 0px;
        }
        .right-section-cover-image {
            padding-left: 12.5px;
            padding-right: 12.5px;
        }
        .right-section-cover-image-height-100 {
            padding-left: 12.5px;
            padding-right: 25px;
        }
        .middle-section-question {
            padding-left: 12.5px;
            padding-right: 0px;
        }
        .middle-section-answer {
            padding-left: 12.5px;
            padding-right: 0px;
        }
        .right-section-hint {
            padding-left: 12.5px;
            padding-right: 12.5px;
        }
    }
}

.connector-back-button {
    position: absolute;
    width: calc(100% - 70px);
    display: flex;
    justify-content: flex-end;
    height: 30px;
    top: 20px;
    .back-button-style {
        height: 40px;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

@media only screen and (max-width: $medium_break_point) {
    .connector-back-button {
        width: calc(100% - 80px);
    }
}

.questionTimerSection {
    width: calc(100% - 110px);
}

.no-internet {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.no-internet-modal {
    .ms-Dialog-main {
        padding: 10%;
        background-color: $backgroundColor;
        color: white;
        border: 1px white solid;
    }
}

.coverImageLoader {
    height: 400px;
    width: 200px;
}

.top-logo-container {
    height: 100%;
}

@media only screen and (max-width: $medium_break_point) {
    .top-logo-container {
        padding-right: 15px;
    }
    .document-cover-image-section {
        margin: 0 !important;
    }
    .zoom-image {
        left: 120px !important;
    }
}

.answer-result-image {
    height: 100px;
    margin: 10px;
}

.zoom-image {
    position: relative;
    top: -40px;
    left: 170px;
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.zoom-image:hover {
    background-color: inherit;
}

.video-question-view {
    border-radius: 10px;
    height: 100%;
    opacity: 0.9;
}

.videoWrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
}

.videoWrapper video {
    object-fit: fill;
}
