.login-background {
    min-height: 100vh;
    background: rgb(0,35,141);
    background: linear-gradient(150deg, rgba(0,35,141,1) 0%, rgba(0,22,95,1) 87%, rgba(0,9,66,1) 100%);
    background-position: center;
    background-repeat: no-repeat;
    justify-content: center;
    background-size: cover;
}

.login-component-container {
    padding: 50px;
    background-color: white;
    border-radius: 5px;
    width: 500px;

    .logo {
        img {
            height: 120px;
        }
    }
}

.login-main-container {
    background-image: url("../../Images/eventBackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    overflow-y: auto;
    .event-parent {
        width: 30%;
        border-radius: 10px;
        background-color: white;
    }
    .event-child {
        padding: 0 10% 10% 10%;
        border-radius: 10px 10px 0 0;
    }
}

.event-logo {
    background-color: #f2f4f7;
    border-radius: 10px 10px 0 0;
    img {
        height: 100px;
    }
}

.login-button {
    width: 45%;
    background-color: #111f4d;
    border: 0;
    font-size: 20px;
}
