.event-main-container {
    background-image: url("../Images/eventBackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    overflow-y: auto;
    .event-parent {
        width: 30%;
        border-radius: 10px;
        background-color: white;
    }
    .event-child {
        padding: 0 10% 10% 10%;
        border-radius: 10px 10px 0 0;
    }
}

.lobby-main-container {
    background-image: url("../Images/backgroundLobby.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    overflow-y: auto;
    .event-parent {
        width: 30%;
        border-radius: 10px;
        background-color: white;
    }
    .event-child {
        padding: 0 10% 10% 10%;
        border-radius: 10px 10px 0 0;
    }
}

.event-logo {
    background-color: #f2f4f7;
    border-radius: 10px 10px 0 0;
    img {
        height: 100px;
    }
}

.login-button {
    width: 45%;
    background-color: #111f4d;
    border: 0;
    font-size: 20px;
}

.get-help-button {
    width: 45%;
    background-color: #e43a19;
    border: 0;
    font-size: 20px;
}

@media only screen and (max-width: 1024px) {
    .event-main-container {
        .event-parent {
            width: 80%;
        }
    }
    .lobby-main-container {
        .event-parent {
            width: 80%;
        }
    }
}
